<template>
    <section class="bg-white py-16 lg:px-4" id="werkwijze" >
      <div class="container mx-auto px-4 grid grid-cols-1 lg:grid-cols-2 gap-8">
        <!-- Left Column: Text Content -->
        <div class="text-gray-800 self-start mt-6 px-4 sm:px-0">
          <hr/>
          <h2 class="text-4xl font-bold mb-6">Medisch Pedicure</h2>
          <p class="text-gray-600 mb-4 text-lg">
            Als medische pedicure heb je naast de basisopleiding de studie medisch pedicure doorlopen.
          </p>
          <p class="text-gray-600 text-lg">
            Naast het behandelen van de diabetische en/of reumatische voet mogen wij <strong>alle</strong> soorten risicovoeten behandelen. Risicovoeten kunnen onder andere ontstaan zijn ten gevolge van diabetes, reuma, hart- en vaatziektes, spasticiteit,ouderdom en verwaarlozing. De medisch pedicure is speciaal opgeleid voor de behandeling van deze doelgroepen. Wij hebben de beschikking over specialistische technieken (antidruktechnieken doormiddel van vilt, nagelregulatie doormiddel van het plaatsen van nagelbeugels en nagelreparatie) waarmee wij complexere voet- en nagelproblematiek kunnen behandelen. Een medisch pedicure is daardoor breder opgeleid dan de pedicure met een aantekening.
          </p>
          <p class="text-gray-600 text-lg">
            Als Medisch pedicure werken wij nauw samen met verschillende disciplines. Bij ernstige klachten of specifieke medische aandoeningen, zullen wij u dan ook gericht doorverwijzen naar een podotherapeut of huisarts.
<br><br>
            Behandeling is mogelijk <strong>bij u thuis</strong> in uw vertrouwde omgeving of <strong>bij ons in de salon</strong>.<br><br>
            Wij zijn gemakkelijk toegankelijk voor rolstoelgebruikers en er is voldoende parkeergelegenheid aanwezig op eigen terrein.
          </p>
          <hr/>
        </div>
  
        <!-- Right Column: Photo Grid -->
        
        <div class="container mx-auto px-4 hidden sm:block lg:mt-48 xl:mt-32 2xl:mt-0">
      <!-- Photo Grid -->
      <div class="grid grid-cols-4 grid-rows-6 gap-5">
        <div class="relative overflow-hidden rounded-lg shadow-md col-span-2 row-span-3 col-start-3">
          <img src="int2.jpg" alt="Foto 1" class="w-full h-full object-cover">
        </div>
        <div class="relative overflow-hidden rounded-lg shadow-md row-span-2 col-start-2 row-start-2">
          <img src="int1.png" alt="Foto 2" class="w-full h-full object-cover">
        </div>
        <div class="relative overflow-hidden rounded-lg shadow-md col-span-2 row-span-3 row-start-4">
          <img src="ext1.jpeg" alt="Foto 3" class="w-full h-full object-cover">
        </div>
        <div class="relative overflow-hidden rounded-lg shadow-md row-span-2 col-start-3 row-start-4">
          <img src="int3.png" alt="Foto 4" class="w-full h-full object-cover">
        </div>
      </div>
    </div>
    
      </div>
    </section>
  </template>
  
  <script>
  export default {
    name: "MedischPedicureSection",
  };
  </script>
  
  <style scoped>
  
  .grid > div img:hover {
    transform: scale(1.05);
    transition: transform 0.3s ease-in-out;
  }
  
  .aspect-square {
    position: relative;
    width: 100%;
    padding-bottom: 75%; /* Smaller size for photos */
  }
  
  .aspect-square img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  </style>
  