<template>
    <nav class="bg-white shadow-md fixed top-0 w-full z-10">
      <div class="container mx-auto px-4 py-3 flex justify-between items-center">
        <!-- Logo -->
        <div class="text-xl font-bold text-teal-500">
          <a href="#" class="hover:text-teal-500">Medisch Pedicure Haaren</a>
        </div>
  
        <!-- Hamburger Icon for Small Screens -->
        <button 
          @click="toggleMenu" 
          class="md:hidden text-teal-500 focus:outline-none">
          <svg v-if="!isMenuOpen" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="w-6 h-6">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" 
              d="M4 6h16M4 12h16m-7 6h7" />
          </svg>
          <svg v-else xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="w-6 h-6">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" 
              d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
  
        <!-- Menu Links (Desktop) -->
        <ul class="hidden md:flex space-x-8 items-center">
          <li><a href="#ons-team" class="hover:text-teal-600">Ons team</a></li>
          <li><a href="#werkwijze" class="hover:text-teal-600">Werkwijze</a></li>
          <li><a href="#tarieven" class="hover:text-teal-600">Tarieven</a></li>
          <li>
            <a 
              href="#contact" 
              class="bg-teal-500 text-white px-4 py-2 rounded hover:bg-teal-600 transition">
              Contact
            </a>
          </li>
        </ul>
      </div>
  
      <!-- Mobile Menu -->
      <transition name="menu-slide" appear>
        <div v-if="isMenuOpen" class="md:hidden bg-white shadow-lg overflow-hidden">
          <ul class="space-y-4 py-4 px-4">
            <li @click="closeMenu"><a href="#ons-team" class="block text-gray-800 hover:text-teal-600">Ons team</a></li>
            <li @click="closeMenu"><a href="#werkwijze" class="block text-gray-800 hover:text-teal-600">Werkwijze</a></li>
            <li @click="closeMenu"><a href="#tarieven" class="block text-gray-800 hover:text-teal-600">Tarieven</a></li>
            <li @click="closeMenu">
              <a 
                href="#contact" 
                class="block bg-teal-500 text-white px-4 py-2 rounded hover:bg-teal-600 transition">
                Contact
              </a>
            </li>
          </ul>
        </div>
      </transition>
    </nav>
  </template>
  
  <script>
  export default {
    name: "ResponsiveNavbar",
    data() {
      return {
        isMenuOpen: false,
      };
    },
    methods: {
      toggleMenu() {
        this.isMenuOpen = !this.isMenuOpen;
      },
      closeMenu() {
        this.isMenuOpen = false;
      },
    },
  };
  </script>
  
  <style scoped>
  /***** Custom Styling *****/
  .menu-slide-enter-active,
  .menu-slide-leave-active {
    transition: max-height 0.3s ease-in-out, opacity 0.3s ease-in-out;
  }
  .menu-slide-enter-from,
  .menu-slide-leave-to {
    max-height: 0;
    opacity: 0;
  }
  .menu-slide-enter-to,
  .menu-slide-leave-from {
    max-height: 500px;
    opacity: 1;
  }
  </style>
  