<template>
  <div class="app-wrapper">
    <ResponsiveNavbar />
    <main class="main-content">
      <ResponsiveHeader />
      <TeamSection />
      <MedischPedicureSection />
      <TarievenSection />
      <ContactComponent />
    </main>
    <FooterComponent />
  </div>
</template>

<script>
import ResponsiveNavbar from './components/Navbar.vue';
import ResponsiveHeader from './components/Header.vue';
import TeamSection from './components/Over.vue';
import MedischPedicureSection from './components/Medischpedicure.vue';
import TarievenSection from './components/Tarieven.vue';
import ContactComponent from './components/Contact.vue';
import FooterComponent from './components/Footer.vue';

export default {
  components: {
    ResponsiveNavbar,
    ResponsiveHeader,
    TeamSection,
    MedischPedicureSection,
    TarievenSection,
    ContactComponent,
    FooterComponent,
  },
};
</script>

<style>

</style>
