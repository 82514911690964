<template>
    <section class="bg-gray-100 py-12" id="ons-team">
      <div class="container mx-auto px-4">
        <h2 class="text-4xl font-bold text-center mb-8 text-gray-800">Ons Team</h2>
  
        <!-- General Information -->
        <div class="mb-12 text-gray-700 text-center">
          <p class="text-center text-xl">
            Welkom op de website van Medisch Pedicure Haaren! Medisch pedicure Haaren is een pedicure salon in het centrum van Haaren. Op dit moment zijn wij met drie dames werkzaam in de salon, verdeeld over verschillende dagen.<br>Maak kennis met ons team:</p>
        </div>
  
        <div class="grid gap-8 md:grid-cols-3 items-start">
          <!-- Employee 1 -->
          <div class="bg-white shadow-lg rounded-lg overflow-hidden hover:shadow-2xl transition flex flex-col items-center p-6">
            <img src="yvonne.png" alt="Werknemer 1" class="w-64 h-64 rounded-full object-cover mb-4">
            <h3 class="text-xl font-bold mb-2 text-gray-800">Yvonne van Baest</h3>
            <p class="text-gray-600 text-center mb-4">'Ik ben sinds mei 2016 gediplomeerd pedicure en sinds 2019 ook medisch pedicure. Ik sta ingeschreven bij Provoet en geregistreerd in het PROcert register. Ik ben werkzaam op maandagmiddag.'
            </p>
            <div class="flex space-x-4 mt-auto">
              <a href="mailto:info@medischpedicurehaaren.nl">
              <button class="w-10 h-10 rounded-full bg-blue-600 text-white flex items-center justify-center hover:bg-blue-700">
                <i class="fas fa-envelope text-lg"></i>
              </button>
            </a>
              <a href="tel:+31627390425">
              <button class="w-10 h-10 rounded-full bg-teal-600 text-white flex items-center justify-center hover:bg-teal-700">
                <i class="fas fa-phone text-lg"></i>
              </button>
            </a>
            </div>
          </div>
  
          <!-- Employee 2 -->
          <div class="bg-white shadow-lg rounded-lg overflow-hidden hover:shadow-2xl transition flex flex-col items-center p-6">
            <img src="petra.png" alt="Werknemer 2" class="w-64 h-64 rounded-full object-cover mb-4">
            <h3 class="text-xl font-bold mb-2 text-gray-800">Petra van der Pas</h3>
            <p class="text-gray-600 text-center mb-4">'Ik ben gediplomeerd pedicure sinds 2002. Ik ben 20 jaar werkzaam geweest als medisch pedicure bij een podotherapeut. Sinds 2022 werkzaam in de salon als pedicure. Ik ben werkzaam op woensdag en donderdag.'' 
            </p>
            <div class="flex space-x-4 mt-auto">
              <a href="mailto:info@medischpedicurehaaren.nl">
              <button class="w-10 h-10 rounded-full bg-blue-600 text-white flex items-center justify-center hover:bg-blue-700">
                <i class="fas fa-envelope text-lg"></i>
              </button>
            </a>
              <a href="tel:+31622931418">
              <button class="w-10 h-10 rounded-full bg-teal-600 text-white flex items-center justify-center hover:bg-teal-700">
                <i class="fas fa-phone text-lg"></i>
              </button>
            </a>
            </div>
          </div>
  
          <!-- Employee 3 -->
          <div class="bg-white shadow-lg rounded-lg overflow-hidden hover:shadow-2xl transition flex flex-col items-center p-6">
            <img src="debbie.png" alt="Werknemer 3" class="w-64 h-64 rounded-full object-cover mb-4">
            <h3 class="text-xl font-bold mb-2 text-gray-800">Debby van Gestel.</h3>
            <p class="text-gray-600 text-center mb-4">'Ik ben gediplomeerd pedicure sinds januari 2024 en vanaf april 2024 werkzaam in de salon. Op dit moment ben ik mij aan het bijscholen tot medisch pedicure en verwacht dit juli 2025 af te ronden. ik ben werkzaam op dinsdag.'</p>
            <div class="flex space-x-4 mt-auto">
              <a href="mailto:info@medischpedicurehaaren.nl">
              <button class="w-10 h-10 rounded-full bg-blue-600 text-white flex items-center justify-center hover:bg-blue-700">
                <i class="fas fa-envelope text-lg"></i>
              </button>
            </a>
              <a href="tel:+31613955233">
              <button class="w-10 h-10 rounded-full bg-teal-600 text-white flex items-center justify-center hover:bg-teal-700">
                <i class="fas fa-phone text-lg"></i>
              </button>
            </a>
            </div>
          </div>
          
        </div>
        <div class="mb-12 text-gray-700 text-center px-4 sm:px-16 lg:px-36 mt-8">
          <p class="text-center text-xl">
            Wij werken met de modernste technieken. Dit houdt in dat we werken met een pedicuremotor op basis van nattechniek. De behandeling is hierdoor pijnloos. Tevens werken wij naar de beroepscode van de voetverzorging, wat inhoudt dat wij hygiënisch, veilig en milieubewust werken.
              <br><br>
              In de salon kunt u terecht voor de behandeling van al uw nagelproblemen -zoals bijvoorbeeld ingroeiende nagels en schimmelnagels, eeltproblemen, likdoorns of kloven.</p>
        </div>
      </div>
    </section>
  </template>
  
  <script>
  export default {
    name: "TeamSection",
  };
  </script>
  
  <style scoped>
  section {
    background-color: #f7fafc;
  }
  </style>
  