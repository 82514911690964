<template>
  <header class="relative bg-cover bg-center" style="height: 50vh; background-image: url('banner.jpg')">
    <!-- Background overlay -->
    <div class="absolute inset-0 bg-white bg-opacity-20"></div>

    <!-- Centered content -->
    <div class="absolute inset-0 flex flex-col items-center justify-center text-center">
      <!-- Logo -->
      <img src="logo.png" alt="Logo" class="w-60 sm:w-80 lg:w-56 xl:w-52 2xl:w-72 h-auto mb-6 mt-12" />

      <!-- Button -->
      <a href="#ons-team">
        <button class="bg-teal-500 text-white text-xl px-6 py-2 rounded hover:bg-teal-600 transition">
          Maak een afspraak
        </button>
      </a>

    </div>
  </header>
</template>

<script>
export default {
  name: "ResponsiveHeader",
};
</script>

<style scoped>
header {
  height: 50vh; /* Zorg dat de hoogte 50% van de viewport is */
  background-size: cover;
  background-position: center;
}

button {
  margin-top: 1rem;
}
</style>
