<template>
  <footer class="footer bg-teal-500 text-white">
    <div class="container mx-auto px-4 grid grid-cols-1 md:grid-cols-3 gap-8 py-6">
      <!-- Left Column: Logo and Slogan -->
      <div class="md:text-center text-left">
        <h3 class="text-2xl font-bold mb-4">Medisch Pedicure Haaren</h3>
        <p class="text-white">Voor professionele voetverzorging en meer.</p>
      </div>
      <!-- Middle Column: Links -->
      <div class="md:text-center text-left">
        <h3 class="text-xl font-bold mb-4">Handige Links</h3>
        <ul class="space-y-2">
          <li><a href="#ons-team" class="text-white hover:underline">Over Ons</a></li>
          <li><a href="#werkwijze" class="text-white hover:underline">Werkwijze</a></li>
          <li><a href="#tarieven" class="text-white hover:underline">Tarieven</a></li>
          <li><a href="#contact" class="text-white hover:underline">Contact</a></li>
        </ul>
      </div>
      <!-- Right Column: Socials -->
      <div class="md:text-center text-left">
  <h3 class="text-xl font-bold mb-4">Neem Contact Op</h3>
  <div class="flex md:justify-center space-x-4">
    <a href="mailto:info@medischpedicurehaaren.nl" class="text-white hover:text-white flex items-center space-x-2">
      <i class="fas fa-envelope fa-lg"></i>
      <span>Mail ons</span>
    </a>
    <a href="tel:0627390425" class="text-white hover:text-white flex items-center space-x-2">
      <i class="fas fa-phone fa-lg"></i>
      <span>Bel ons</span>
    </a>
  </div>
</div>

    </div>
    <!-- Footer Note -->
    <div class="bg-teal-700 text-center text-white py-4">
      <p>
        Website ontwikkeld door
        <a href="https://www.launch-media.nl" class="text-teal-100 font-bold hover:underline">Launch Media</a>
      </p>
    </div>
  </footer>
</template>

<script>
export default {
  name: "FooterComponent",
};
</script>
