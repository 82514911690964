<template>
    <section class="bg-gray-50 pt-16  px-4 sm:px-4 lg:px-8" id="tarieven">
      <div class="container mx-auto grid grid-cols-1 lg:grid-cols-2 gap-16">
        <!-- Left Column: Price Tables -->
        <div class="space-y-8">
            <h1 class="text-2xl font-bold mb-4 text-gray-800">Tarieven</h1>
          <div class="bg-white shadow-md rounded-lg p-6">
            <h2 class="text-2xl font-bold mb-4 text-gray-800">Pedicure behandeling</h2>
            <table class="w-full text-left border-collapse">
              <thead>
                <tr>
                  <th class="border-b pb-2 text-gray-600">Dienst</th>
                  <th class="border-b pb-2 text-gray-600">Prijs</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="py-3 text-gray-800  text-base sm:text-lg">Basisbehandeling</td>
                  <td class="py-3 text-gray-800  text-base sm:text-lg">&euro; 35,00</td>
                </tr>
                <tr class="bg-gray-100">
                  <td class="py-3 text-gray-800  text-base sm:text-lg">Korte- of Deelbehandeling</td>
                  <td class="py-3 text-gray-800  text-base sm:text-lg">&euro; 22,00</td>
                </tr>
                <tr>
                  <td class="py-3 text-gray-800  text-base sm:text-lg">Ambulante Behandeling Haaren</td>
                  <td class="py-3 text-gray-800  text-base sm:text-lg">&euro; 37,50</td>
                </tr>
                <tr class="bg-gray-100">
                  <td class="py-3 text-gray-800  text-base sm:text-lg">Manicure</td>
                  <td class="py-3 text-gray-800  text-base sm:text-lg">&euro; 7,50</td>
                </tr>
              </tbody>
            </table>
          </div>
  
          <div class="bg-white shadow-md rounded-lg p-6">
            <h2 class="text-2xl font-bold mb-4 text-gray-800">Cosmetische behandeling</h2>
            <table class="w-full text-left border-collapse text-lg">
              <thead>
                <tr>
                  <th class="border-b pb-2 text-gray-600">Dienst</th>
                  <th class="border-b pb-2 text-gray-600">Prijs</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="py-3 text-gray-800  text-base sm:text-lg">Gellak</td>
                  <td class="py-3 text-gray-800  text-base sm:text-lg">&euro; 27,50</td>
                </tr>
                <tr class="bg-gray-100">
                  <td class="py-3 text-gray-800  text-base sm:text-lg">Basisbehandeling + Gellak</td>
                  <td class="py-3 text-gray-800  text-base sm:text-lg">&euro; 50,00</td>
                </tr>
                <tr>
                  <td class="py-3 text-gray-800  text-base sm:text-lg">Basisbehandeling + Nagels Lakken</td>
                  <td class="py-3 text-gray-800  text-base sm:text-lg">&euro; 42,00</td>
                </tr>
              
              </tbody>
            </table>
          </div>
        </div>
  
        <!-- Right Column: Text and Image -->
        <div class="flex flex-col px-4 sm:px-0">
          <h2 class="text-2xl font-bold mb-4 text-gray-800">Meer informatie</h2>
          <p class="text-gray-800 mb-4 text-lg">
            Hiernaast vindt u een overzicht van de prijzen die ik hanteer voor de verschillende diensten.</p>
<p class="text-lg">De standaard pedicure behandeling:</p>
<br>

<ul class="custom-bullets text-lg">
  <li>Desinfecteren van de huid</li>
  <li>Nagelomgeving schoonmaken</li>
  <li>Eelt glad afwerken met de frees</li>
  <li>Overtollig eelt verwijderen</li>
  <li>Eventuele likdoorns verwijderen</li>
  <li>Behandeling van kloven</li>
  <li>Kleine voetmassage met een voetencrème</li>
</ul><br>

<p class="text-lg">
Indien u een afspraak wilt annuleren, dan horen wij dit graag zo spoedig mogelijk. Een afspraak dient minimaal 24 uur van te voren-, telefonisch-, te worden afgezegd. Bij geen of een te late afzegging zijn wij genoodzaakt de gereserveerde tijd bij u in rekening te brengen.
          </p>
          
        </div>
      </div>
    </section>
  </template>
  
  <script>
  export default {
    name: "TarievenSection",
  };
  </script>
  
  <style scoped>
  section {
    background-color: #f9fafb;
  }
  
  table {
    border-spacing: 0;
  }
  
  th {
    font-weight: 600;
    text-transform: uppercase;
    font-size: 0.875rem;
  }
  
  td {
    padding: 0.5rem 0;
  }

  .custom-bullets {
  list-style: none;
  padding: 0;
  margin: 0;
}

.custom-bullets li {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem; /* Ruimte tussen de items */
}

.custom-bullets li::before {
  content: "\f00c"; /* FontAwesome voetzool unicode */
  font-family: "Font Awesome 5 Free"; /* FontAwesome familie */
  font-weight: 900; /* Zorgt ervoor dat het icoon correct wordt weergegeven */
  color: #18b2a2; /* Kleur van het icoon */
  font-size: 1.2rem; /* Grootte van het icoon */
  margin-right: 0.5rem; /* Ruimte tussen het icoon en de tekst */
}

  </style>
  