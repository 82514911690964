<template>
    <section class="bg-gray-50 py-16 px-4 sm:px-4 lg:px-8" id="contact">
      <div class="container mx-auto px-4 grid grid-cols-1 lg:grid-cols-2 gap-16 text-lg">
        <!-- Left Column: Contact Details -->
        <div>
          <h2 class="text-3xl font-bold mb-4 text-gray-800">Neem contact op</h2>
          <p class="text-gray-600 mb-4">Heeft u vragen of wilt u een afspraak maken? Neem contact met ons op via onderstaande gegevens.</p>
          <ul class="text-gray-700 space-y-6">
            <li>
              <h3 class="text-lg font-bold text-gray-800">Yvonne van Baest</h3>
              <a href="tel:+31627390425" class="text-teal-500 hover:underline">06-27390425</a>
            </li>
            <li>
              <h3 class="text-lg font-bold text-gray-800">Petra van der Pas</h3>
              <a href="tel:+31622931418" class="text-teal-500 hover:underline">06-22931418</a>
            </li>
            <li>
              <h3 class="text-lg font-bold text-gray-800">Debbie van Gestel</h3>
              <a href="tel:+31613955233" class="text-teal-500 hover:underline">06-13955233</a>
            </li>
            <li class="flex items-center">
              <i class="fas fa-map-marker-alt text-teal-500 mr-3"></i>
              <span>Haarendijk 5, 5076TL Haaren</span>
            </li>
            <li class="flex items-center">
              <i class="fas fa-envelope text-teal-500 mr-3"></i>
              <a href="mailto:info@medischpedicurehaaren.nl" class="text-teal-500 hover:underline">info@medischpedicurehaaren.nl</a>
            </li>
            <li class="flex items-center">
              <i class="fas fa-briefcase text-teal-500 mr-3"></i>
              <span>Kvk: 70660417</span>
            </li>
          </ul>
        </div>
  
        <!-- Right Column: Google Maps and Salon Image Overlay -->
        <div class="relative rounded-lg overflow-hidden">
          <!-- Google Maps Embed -->
          <iframe 
            class="w-full h-96"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2478.2651287077624!2d5.227658776968779!3d51.60003050393165!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c6ea3795fedc6d%3A0xa82c64dd9084b009!2sHaarendijk%205%2C%205076%20TL%20Haaren!5e0!3m2!1snl!2snl!4v1735421846829!5m2!1snl!2snl"
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade">
          </iframe>
  
          <!-- Salon Image Overlay -->
          <div class="absolute bottom-4 right-4 bg-white p-2 rounded-lg shadow-md">
            <img src="ext1.jpeg" alt="Salon Exterior" class="w-32 h-32 rounded-md object-cover">
          </div>
        </div>
      </div>
    </section>
  </template>
  
  <script>
  export default {
    name: "ContactComponent",
  };
  </script>
  
  <style scoped>
  section {
    background-color: #f9fafb;
  }
  
  iframe {
  width: 100%;
  min-height: 300px; /* Zorg dat het iframe altijd zichtbaar blijft */
  height: auto;
}

  
  .absolute img {
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  </style>